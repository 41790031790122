import { contract, DEFAULT_CHAIN } from '../hooks/constant';
import multiCallAbi from '../json/multicall.json';
import { getWeb3 } from "./connectors";

export const MulticallContractWeb3 = async (calls = []) => {
  let web3 = getWeb3(DEFAULT_CHAIN);
  let multicallAddress =  contract[DEFAULT_CHAIN].MULTICALL_ADDRESS;
  const mc = new web3.eth.Contract(multiCallAbi, multicallAddress);
  const callRequests = calls.map((call) => {
    const callData = call.encodeABI();
    return {
      target: call._parent._address,
      callData,
    };
  });

  const { returnData } = await mc.methods
    .aggregate(callRequests)
    .call({});

  let finalData = returnData.map((hex, index) => {
    const types = calls[index]._method.outputs.map((o) =>
      o.internalType !== o.type && o.internalType !== undefined ? o : o.type
    );

    let result = web3.eth.abi.decodeParameters(types, hex);

    delete result.__length__;

    result = Object.values(result);

    return result.length === 1 ? result[0] : result;
  });

  return finalData;
}

export const multiCallContractConnect = (chainId) => {
  let multicallAddress =  contract[DEFAULT_CHAIN].MULTICALL_ADDRESS;
  let web3 = getWeb3(chainId);
  return new web3.eth.Contract(multiCallAbi, multicallAddress);
}

