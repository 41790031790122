export const supportNetwork = { 
    // 5 : {
    //    name : "Goerli",
    //    chainId : 5,
    //    rpc : "https://goerli.infura.io/v3/c340baf441a74c52ba27da70425a1ae2",
    //    symbol : 'ETH'
    //},
    1 : {
         name : "Mainnet",
         chainId : 1,
         rpc : "https://mainnet.infura.io/v3/c340baf441a74c52ba27da70425a1ae2",
         symbol : 'ETH'
    },
    // 43113 : {
    //     name : "Testnet Avalanche",
    //     chainId : 43113,
    //     rpc : "https://api.avax-test.network/ext/bc/C/rpc",
    //     symbol : 'AVAX'
    // }
}

export const RPC_URLS = {
    // 56: "https://bsc-dataseed1.defibit.io/",
    // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // 5: "https://goerli.infura.io/v3/c340baf441a74c52ba27da70425a1ae2",
    // 4: "https://rinkeby.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    // 137 : "https://polygon-rpc.com/",
    1 : "https://mainnet.infura.io/v3/c340baf441a74c52ba27da70425a1ae2"
    //  43113 : "https://api.avax-test.network/ext/bc/C/rpc"
  };
