import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import nftAbi from '../json/nftAbi.json';
import tokenAbi from '../json/token.json'
import { contract } from "./constant";
import { MulticallContractWeb3 } from "./useContracts";
import { getWeb3 } from "./connectors";
import { DEFAULT_CHAIN } from "./constant";
import { toast } from "react-toastify";


export const useCommonStats = (updater) => {
    let { chainId } = useWeb3React();
    let web3 = getWeb3(DEFAULT_CHAIN);


    const [stats, setStats] = useState({
        MAX_SUPPLY: 0,
        NativeCost: 0,
        TokenCost: 0,
        communitySupply: 0
    });


    let nftContract = new web3.eth.Contract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await MulticallContractWeb3([
                    nftContract.methods.Max_Community_Mint(), //0
                    nftContract.methods.NativeCost(), //1
                    nftContract.methods.TokenCost(), //2
                    nftContract.methods.communitySupply() //3
                ]);

                setStats({
                    MAX_SUPPLY: data[0],
                    NativeCost: data[1] / Math.pow(10,18),
                    TokenCost: data[2] / Math.pow(10,18),
                    communitySupply: data[3]
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater, chainId]);

    return stats;
}


export const useAccountStats = (updater) => {
    let { chainId, account } = useWeb3React();
    let web3 = getWeb3(DEFAULT_CHAIN);


    const [stats, setStats] = useState({
        balanceOf: 0,
        tokenBalance: 0,
        isApprove : false
    });


    let nftContract = new web3.eth.Contract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS);
    let tokenContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].C12TOKEN_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await MulticallContractWeb3([
                    nftContract.methods.balanceOf(account , 2), //0
                    tokenContract.methods.balanceOf(account), //1
                    tokenContract.methods.allowance(account , contract[DEFAULT_CHAIN].NFT_ADDRESS ) //1
                ]);

                setStats({
                    balanceOf: data[0],
                    tokenBalance: data[1] / Math.pow(10,18),
                    isApprove : data[2] / Math.pow(10,18) > 1000000000 ? true : false
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (account) {
            fetch();
        }
        else {
            setStats({
                balanceOf: 0,
                tokenBalance: 0
            })
        }
        // eslint-disable-next-line
    }, [updater, chainId, account]);

    return stats;
}