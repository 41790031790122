import React, { useState, useEffect } from 'react';
import { useCommonStats, useAccountStats } from '../hooks/useStats';
import { useWeb3React } from "@web3-react/core";
import { contract, DEFAULT_CHAIN, trimAddress } from '../hooks/constant';
import { supportNetwork } from '../hooks/network';
import { formatPrice, getContract } from '../hooks/contractHelper';
import { toast } from 'react-toastify';
import nftAbi from '../json/nftAbi.json';
import tokenAbi from '../json/token.json';
import { parseUnits } from 'ethers/lib/utils';
import { getWeb3 } from '../hooks/connectors';
import Connect from './Connect';
import { ethers } from 'ethers';
import PayWithTransak from './PayWithTransak';
import singleLogo from '../assets/img/c12-single-logo.png';
import mainLogo from '../assets/img/c12-main-logo.png';
import lionOfJudahImg from '../assets/img/lion-of-judah.png';
import videoBG from '../assets/video/background-bg.mp4';
import '../assets/css/styles.css';
import '../assets/css/responsive.css';
import SocialLinks from './SocialLinks';
import HowToPurchase from './HowToPurchase';

// Export Function
export default function Mint() {
    const { account, chainId, library } = useWeb3React();
    const [updater, setUpdater] = useState(1);
    const commonStats = useCommonStats(updater);
    const accStats = useAccountStats(updater);
    const [amount, setAmount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [loading, setLoading] = useState(false);
    const [ctype, setCtype] = useState('1');
    const [paymentMethod, setPaymentMethod] = useState('wallet');

    useEffect(() => {

        if (ctype === '1') {
            setTotalCost(commonStats.NativeCost * amount);
        }
        else {
            setTotalCost(commonStats.TokenCost * amount);
        }

        // eslint-disable-next-line
    }, [amount, ctype]);


    const handleMint = async () => {
        try {
            setLoading(true);
            if (account) {
                if (chainId === DEFAULT_CHAIN) {
                    if (parseInt(amount) > 0 && (ctype === "1" || ctype === "2")) {
                        let nftContract = getContract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS, library);
                        let tx;
                        if (ctype === "1") {
                            tx = await nftContract.publicNativeMint(amount, { 'from': account, value: parseUnits(totalCost.toString(), 18) });
                        }
                        else if (ctype === "2") {
                            tx = await nftContract.publicTokenMint(amount, { 'from': account });
                        }
                        else {
                            toast.error('Selected type is not supported!!');
                            return false;
                        }

                        toast.loading('Waiting for confirmation...');
                        var interval = setInterval(async function () {
                            let web3 = getWeb3(DEFAULT_CHAIN);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('Transaction completed successfully.');
                                    setLoading(false);
                                    setUpdater(Math.random());

                                }
                                else if (response.status === false) {
                                    toast.dismiss();
                                    toast.error('Oops! Transaction failed. Please try again.');
                                    setLoading(false);
                                }
                                else {
                                    toast.dismiss();
                                    toast.error('Oops! Something went wrong. Please try again');
                                    setLoading(false);
                                }
                            }
                        }, 5000);
                    } else {
                        toast.error('Please select a number of quanity to mint.');
                        setLoading(false);
                    }
                } else {
                    toast.error('Please connect goerli testnet network!');
                    setLoading(false);
                }
            } else {
                toast.error('Please connect your wallet first!');
                setLoading(false);
            }
        } catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }

    const handleApprove = async () => {
        try {
            setLoading(true);
            if (account) {
                if (chainId === DEFAULT_CHAIN) {

                    let tokenContract = getContract(tokenAbi, contract[DEFAULT_CHAIN].C12TOKEN_ADDRESS, library);
                    let amount = ethers.utils.parseEther('1000000000000000000000').toString();
                    let tx = await tokenContract.approve(contract[DEFAULT_CHAIN].NFT_ADDRESS, amount, { 'from': account });

                    toast.loading('Waiting for confirmation..');
                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('Your last transaction is success!!');
                                setLoading(false);
                                setUpdater(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                            }
                        }
                    }, 5000);


                }
                else {
                    toast.dismiss();
                    toast.error('Please connect goerli testnet network!!');
                    setLoading(false);
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect wallet!!');
                setLoading(false);
            }
        }
        catch (err) {
            toast.dismiss();
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }


    return (
        <>
            {/* Header */}
            <section className="main-header">
                <div className="section-wrapper heading-container">
                    <div className="company-logo">
                        <div className="bg-logo-overlay" style={{ backgroundImage: `url(${singleLogo})`}}></div>
                        <div className="logo-wrapper">
                            <a className="logo-back-btn" href="https://lionofjudah.carbon12.co/"><i className="fa fa-arrow-left"></i></a>
                            <a className="logo-main" href="https://lionofjudah.carbon12.co/"><img src={mainLogo} alt="Carbon12 Logo" /></a>
                        </div>
                    </div>
                    <div className="connect-wallet">
                        <Connect />
                    </div>
                </div>
            </section>

            {/* Section Body */}
            <section className="main-section-body">
                <div className="video-container">
                    <video autoPlay loop muted>
                        <source src={videoBG} />
                    </video>
                    <div className="video-overlay"></div>
                </div>
                <div className="section-wrapper minting-section">
                    <div className="minting-container">
                        <div className="minting-heading">
                            <img src={lionOfJudahImg} alt="Lion of Judah" />
                            <h1>Lion of Judah</h1>
                            <h4>NFT Public Sale</h4>
                            <p>{account ? `Account Address : ${trimAddress(account)}` : ''}</p>
                        </div>
                        <div className="minting-area-content">
                            <div className="minting-area minting-area-details">
                                <div className="mint-desc">
                                    <ul>
                                        <li>Quantity</li>
                                        <li>Select Currency</li>
                                        <li>Select Payment</li>
                                        <li>NFT Balance</li>
                                        <li>C12 Balance</li>
                                        <li>Total Price</li>
                                    </ul>
                                </div>
                                <div className="mint-fields">
                                    <ul>
                                        <li>
                                            <span><i className="fa fa-caret-down"></i></span>
                                            <select className="form-field" name="mint_value" onChange={(e) => {
                                                setAmount(e.target.value)
                                            }}>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </select>
                                        </li>
                                        <li>
                                            <span><i className="fa fa-caret-down"></i></span>
                                            <select className="form-field" name="ctype" onChange={(e) => {
                                                setCtype(e.target.value)
                                            }}>
                                                <option value="1">Ethereum(ETH)</option>
                                                <option value="2">Carbon 12 (C12)</option>
                                            </select>
                                        </li>
                                        <li>
                                            <span><i className="fa fa-caret-down"></i></span>
                                            <select className="form-field" name="paymentMethod" onChange={(e) => {
                                                setPaymentMethod(e.target.value)
                                            }}>
                                                <option value="wallet">Pay with Wallet</option>
                                                <option value="transak">Pay with Credit Card</option>
                                            </select>
                                        </li>
                                        <li>{accStats.balanceOf ? accStats.balanceOf : 0}</li>
                                        <li>{accStats.tokenBalance ? formatPrice(accStats.tokenBalance) : 0}</li>
                                        <li>
                                            {totalCost ? formatPrice(totalCost) : 0} {ctype === '1' ? supportNetwork[DEFAULT_CHAIN].symbol : 'C12'} + GAS
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Button */}

                            {paymentMethod === 'transak' ? (
                                <PayWithTransak 
                                    walletAddress={account ? account : ''}
                                />
                            ) : (
                                <>
                                    {!accStats.isApprove && ctype === '2' ? (
                                        <button onClick={() => handleApprove()} className="mint-button-big" disabled={loading}>
                                            <div className="min-btn-border">
                                                <div className="min-btn-img">
                                                    <img src={singleLogo} alt="Carbon12 Logo - Single"/>
                                                </div>
                                            </div>
                                            {loading ? `Loading...` : 'Approve'}
                                        </button>
                                    ) : (
                                        <button onClick={() => handleMint()} className="mint-button-big" disabled={loading}>
                                            <div className="min-btn-border">
                                                <div className="min-btn-img">
                                                    <img src={singleLogo} alt="Carbon12 Logo - Single"/>
                                                </div>
                                            </div>
                                            {loading ? `Loading...` : 'Mint Now'}
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {/* Contract Address */}
                    <div className="contract-address">
                        <h3>Contract Address</h3>
                        <h4>
                            <a
                                href={`https://etherscan.io/address/${contract[DEFAULT_CHAIN].NFT_ADDRESS ? contract[DEFAULT_CHAIN].NFT_ADDRESS : ' - '}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>{contract[DEFAULT_CHAIN].NFT_ADDRESS ? contract[DEFAULT_CHAIN].NFT_ADDRESS : ' - '}</span>
                            </a>
                        </h4>
                        
                        {/* How To Purchase */}
                        <HowToPurchase />
                        
                        {/* Social Links */}
                        <SocialLinks />
                    </div>
                </div>
            </section>
        </>
    )
}
