import React from 'react'
import transakSDK from '@transak/transak-sdk';
import singleLogo from '../assets/img/c12-single-logo.png';

export default function PayWithTransak({ walletAddress }) {
    const environment = 'PRODUCTION';
    let transak = new transakSDK({
        apiKey: (environment==='STAGING') ? 
            /* STAGING key */ '12a14447-4cbe-4e51-b829-bb60e7897275' : 
            /* PRODUCTION key */ '29398742-283c-4ba0-b904-2faebbae564b',
        environment: environment,
        walletAddress: walletAddress,
        fiatAmount: 175,
        countryCode: 'US',
    });

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {
        console.log(data);
    });

    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
        transak.close();
    });

    // This will trigger when the user marks payment is made
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
        console.log(orderData);
        transak.close();
    });

    return (
        <button onClick={() => transak.init()} className="mint-button-big">
            <div className="min-btn-border">
                <div className="min-btn-img">
                    <img src={singleLogo} alt="Carbon12 Logo - Single"/>
                </div>
            </div>
            Buy Now
        </button>
    )
}
