import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
    NoEthereumProviderError
} from "@web3-react/injected-connector";
import { useEffect, useState } from "react";
import { injected, walletconnect, coinbaseWallet } from "../hooks/connectors";
import Modal from 'react-bootstrap/Modal';
import useEagerConnect from '../hooks/useWeb3';
import metamaskIcon from '../assets/img/metamask.svg';
import coinbaseIcon from '../assets/img/coinbase.svg';
import walletconnectIcon from '../assets/img/walletconnect.svg';
import localStorage from "local-storage";


export const Connect = function () {
    const context = useWeb3React();
    const { connector, account, activate, deactivate, active, error } = context;
    const [show, setShow] = useState(false);


    useEffect(() => {
        if (account) {
            localStorage.set('address', account);
        }
    }, [account])


    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();
    useEagerConnect();
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);


    function getErrorMessage(error) {

        if (error instanceof NoEthereumProviderError) {
            const dappUrl = window.location.href; // TODO enter your dapp URL. 
            let metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
            window.open(metamaskAppDeepLink)
        }
        if (error instanceof UnsupportedChainIdError) {
            return <span className="btn-text" onClick={(e) => switchNetwork(1)}>Switch Network</span>;
        }

        deactivate(injected);
    }

    const activating = (connection) => connection === activatingConnector;
    const connected = (connection) => connection === connector;

    const switchNetwork = (networkid) => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${networkid.toString(16)}` }]
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            {
                error &&
                <button type="button" className="connect-btn" onClick={() => {
                    setActivatingConnector();
                }}>
                    {getErrorMessage(error)}
                </button>
            }
            {!error &&
                <>
                    {active && (connected(injected) || connected(walletconnect) || connected(coinbaseWallet)) &&
                        <button type="button" className="connect-btn" onClick={() => {
                            setActivatingConnector();
                            deactivate(injected);
                            deactivate(walletconnect);
                            deactivate(coinbaseWallet);
                        }}>
                            Disconnect Wallet
                        </button>
                    }
                    {!active && (!connected(injected) || !connected(walletconnect) || !connected(coinbaseWallet)) &&
                        <button type="button" className="connect-btn" onClick={() => {
                            setShow(!show);
                        }}>
                            {(activating(injected) || activating(walletconnect) || activating(coinbaseWallet)) && <span className="btn-text">Connecting...</span>}
                            {(!activating(injected) || !activating(walletconnect) || !activating(coinbaseWallet)) && <span className="btn-text">Connect Wallet</span>}
                        </button>
                    }
                </>
            }

            <Modal className="connectWalletModal" show={show} onHide={() => setShow(false)}>
                <Modal.Header>
                    <Modal.Title>Connect wallet</Modal.Title>
                    <span className="modalCloseBtn" onClick={() => { setShow(!show); }}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content-wrapper">
                        <ul className="connectWalletWrapper">
                            <li>
                                <button className="conn-button-big metamaskIconBtn" onClick={() => {
                                    activate(injected);
                                    setShow(false);
                                }}>
                                    <div className="conn-btn-border">
                                        <div className="conn-btn-img">
                                            <img src={metamaskIcon} alt="Wallet Connect"/>
                                        </div>
                                    </div>
                                    Metamask
                                </button>
                            </li>
                            <li>
                                <button className="conn-button-big coinbaseWalletBtn" onClick={() => {
                                    activate(coinbaseWallet);
                                    setShow(false);
                                }}>
                                    <div className="conn-btn-border">
                                        <div className="conn-btn-img">
                                            <img src={coinbaseIcon} alt="Wallet Connect"/>
                                        </div>
                                    </div>
                                    Coinbase
                                </button>
                            </li>
                            <li>
                                <button className="conn-button-big walletConnectBtn" onClick={() => {
                                    activate(walletconnect);
                                    setShow(false);
                                }}>
                                    <div className="conn-btn-border">
                                        <div className="conn-btn-img">
                                            <img src={walletconnectIcon} alt="Wallet Connect"/>
                                        </div>
                                    </div>
                                    WalletConnect
                                </button>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment >
    );
};

export default Connect;