import { ethers } from "ethers";
import { getWeb3 } from "./connectors";
import { DEFAULT_CHAIN } from "./constant";


export const getContract = (abi, address, library) => {
  try{
    return new ethers.Contract(address, abi, library.getSigner())
  }
  catch{
    return false;
  }
}


export const formatPrice = (num) => {
  
  return new Intl.NumberFormat('en-US').format(parseFloat(num).toFixed(8));
}


export const getWeb3Contract = (abi, address) => {
  let web3 = getWeb3(DEFAULT_CHAIN);
  return new web3.eth.Contract(abi, address);
}


