import React from 'react';
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import SocialLinks from './SocialLinks';

export default function HowToPurchase() {
    const [show, setShow] = useState(false);
    return (
        <>
            <h2 className="htp-link">
                <button onClick={() => { setShow(!show); }}>
                    <span>How to Purchase?</span>
                </button>
            </h2>
            <Modal className="howToPurchaseModal" show={show} onHide={() => setShow(false)}>
                <Modal.Header>
                    <Modal.Title>How to purchase Lion of Judah NFT</Modal.Title>
                    <span className="modalCloseBtn" onClick={() => { setShow(!show); }}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content-wrapper">
                        <div className="video-content-wrapper">
                            <div className="video-content"><iframe src="https://www.loom.com/embed/ec9ed27d35754ced90d2425397655078" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen title="How to purchase Lion of Judah NFT"></iframe></div>
                        </div>
                        <div className="video-content-details">
                            <h2>Join Our Community to be Notified of When to Vote in Our DAO</h2>
                            <p>You will need to join our online communities to be notified of when to vote for the projects in the Carbon12 NFT DAO. Please use the links below to join our community.</p>
                            <SocialLinks />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
