export const trimAddress = (addr) => {
  return `${addr.substring(0, 6) }...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  1 : {
    MULTICALL_ADDRESS : "0x5e227ad1969ea493b43f840cff78d08a6fc17796",
    NFT_ADDRESS : "0x82BeEc866ae3A1a5FC1f8bB7A3DCCD17E223C949",
    C12TOKEN_ADDRESS : "0x65526D2B86fF1aC0a3a789FC6fF9C36d35673F1B"
  }
}

export const DEFAULT_CHAIN = 1;
export const MAX_MINT=2;

