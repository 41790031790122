import Home from './Component/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from "./Component/ScrollToTop";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mint from './Component/Mint';

function App() {
  return (

    <Router>
      <ScrollToTop>
        <ToastContainer pauseOnFocusLoss={false} />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/mint">
            <Mint />
          </Route>
        </Switch>
        {/* <Footer /> */}
      </ScrollToTop>
    </Router>

  );
}

export default App;
